<template>
 
 
    <div class="all" ref="el">
   
   

      <div id="back">
        <a href="/taskmanager" class="backbutton"> {{symbol }}任务面板</a>
      </div>
      <button @click="reverse" v-if="false">逆序</button>

    <div class="bookpage">
      


   <singlepridebook  :treedataProp = treedataArray  :bookyear = bookyear   :key=diffkey :tasktypedataProp=tasktypedata   :usernamepro = username  />

    </div>
    <div class="sidebar booklist">
  <h2 class="handwritingfont">你的故事</h2>
  <ul class="book-list">
   
      <li class="book" v-for="year in years" :key="year">
        <div class="firstpage bookcover" v-on:click="getpride(Number(year))"  style="background-image:url('https://images-1255367272.cos.ap-chengdu.myqcloud.com/2019-02-27%20201251.jpg')">
        <h3 class="book-title">{{ year }}年</h3>
      </div>
      </li>
    
  </ul>
</div>
    </div>



    </template>
    
    <script>
    // import Taskrecursiontree from "./taskrecursiontree";
    import  singlepridebook  from "./singlebook"
    import '@ksedline/turnjs';
    import { useFullscreen } from '@vueuse/core'

   
    import {  onUpdated } from 'vue'
    import moment from 'moment';
    import { ref } from 'vue';
    import configure_options from '../configure'

    const axios = require('axios').default;
    const $ = require('jquery');
     window.$ = $;


     const el = ref(null)
    //  const loaded =ref(false)
    function isNumber(value) {
  return typeof value === "number";
}
    export default {
      name: 'pridebook',
      props: {
        msg: String
      },
      components:{
      
        singlepridebook
    },
      data(){
        return{
            symbol:"<",
            tasktypedata:"pridetask",
            bookyear:2024,
            treeData:{},
            treedataArray:[],
            username:"atomTask",
            years:[],
            diffkey:2572
        
        }
      },
      methods:{
        toggle(){
          useFullscreen(el)
        },
 
        reverse(){
          this.diffkey +=1
        this.treedataArray.reverse();

        },
       
        getpride(datetype){
          this.treedataArray  = []
            // alert("we")
          this.showpridetab= true;
          this.showloading = true;
          this.tasktypedata = "pridetask"
        this.showtoolboxdata = false;
        this.showcheckboxdata = false;
        this.todaytabstatus = "no"
        this.tomorrowtabstatus = "no"
        this.pridetabstatus = "tabactive"
        // alert("获取明天的消息")
          var env =  configure_options["env"]
       
        this.diffkey +=1
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
        var datetypejson = "todaypridejson"
        if(datetype=="week"){datetypejson = "weekpridejson"
      
      }
        if(datetype=="today"){datetypejson= "todaypridejson"
     
      }
      if(datetype=="month"){datetypejson= "monthpridejson"
        // document.getElementsByClassName("mpb")[0].style.backgroundColor="blue"
        // document.getElementsByClassName("wpb")[0].style.backgroundColor="grey"
        // document.getElementsByClassName("tpb")[0].style.backgroundColor='grey'
      }

      if(datetype=="year"){datetypejson= "pridebookjson"
        // document.getElementsByClassName("mpb")[0].style.backgroundColor="blue"
        // document.getElementsByClassName("wpb")[0].style.backgroundColor="grey"
        // document.getElementsByClassName("tpb")[0].style.backgroundColor='grey'
      }
      var year = -11
      if (isNumber(datetype)){
        //  alert(datetype)
        datetypejson="pridebookjsonyear"
        this.bookyear = datetype
        year =  datetype
      }

    
        axios.get(serverhost+"/v1/pride",{headers:{"client":datetypejson,"parseyear":year}}).then(
        response=>{
        //在这里搜集到会员信息
     
       

        var data = response.data
       this.username = data.username
        console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
        console.log(data);
      // console.log(status);
      // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        // var searchedtasks = data["memories"][0]["Alldays"]

        //将所有的任务推进来
        var searchedtasks=[]
        //这里尝试分天推入

       var alldaysTasksArray = []
        for(var t=0;t<data["memories"].length;t++){
          var singledayTasksArray= []
          for(var y=0;y<data["memories"][t]["Alldays"].length;y++){
            searchedtasks.push(data["memories"][t]["Alldays"][y])
            singledayTasksArray.push(data["memories"][t]["Alldays"][y])  
        }
          alldaysTasksArray.push({"daytime":data["memories"][t].Name,"tasks":singledayTasksArray})
          console.log(data["memories"][t]["Alldays"])
        }
      

        function buildTree(array) {
        
        
        //每一天都需要buildtree   

        var alltasksid = []
        var allparentid = []
        for(var i =0 ;i<array.length;i++){
            array[i].children = []
            alltasksid.push(array[i].ID.toString())
            allparentid.push(array[i].parentid)
            // 尝试在这里加到里面去
            // searchedtopics[i].folder = false
            // if(searchedtopics[i].subKeys.length>0){
            //   searchedtopics[i].folder  = true
            // }
            // console.log(searchedtopics[i].key,searchedkey)
            // if (searchedtopics[i].key==searchedkey){
            //   console.log("检查")
              
            //   searchedtopics[i].parentKey = null 
            // }
          }
  
        
            let tree = []


        for (let i = 0; i < array.length; i++) {
            // if (array[i].parentid) {
            // //如果undifined
            // let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
            // console.log(parent);
            // parent.children.push(array[i]);
            // } else {
            // tree.push(array[i])
            // }
            console.log(i)
            console.log(alltasksid.includes(array[i].parentid))
            if(array[i].parentid=="unspecified"||alltasksid.includes(array[i].parentid)==false){
            console.log("应该在第一层")

            tree.push(array[i])
            }else{
            //将父亲节点弹出来
            let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
            console.log(array[i].parentid)
            console.log(parent);
            parent.children.push(array[i]);
            }


        }
        return tree
        }

        const tasktree= buildTree(searchedtasks)

        this.treeData = {
            name: "这是今天的任务系列,注意这里的数据",
            root:true,
            children:tasktree
        }

       var treedataArray =[]
       console.log(`alldaysTaskArray--->`)
       console.log(alldaysTasksArray)
       for(var j=0;j<alldaysTasksArray.length;j++){
        var singledaydata = alldaysTasksArray[j].tasks
        var newtree = buildTree(singledaydata)
        console.log("new tree show")
        console.log(newtree)

        var a = moment(alldaysTasksArray[j].daytime, "YYMMDD");
       var timestring  = a.format("MM月DD日")
        treedataArray.push({
            name: "这是今天的任务系列,注意这里的数据",
            daytime:timestring,
            root:true,
            children:newtree
        })
       }

       this.treedataArray = treedataArray

       console.log("最终数据")
       console.log(treedataArray)
        this.showloading = false;
        console.log("final return")
        console.log(tasktree)
   


     }
   ).catch(error=>{console.log(error)})
  
        },


      getprideyearDate(){
        var env =  configure_options["env"]
         
       
         console.log(configure_options["app_setting"][env])
         var serverhost =configure_options["app_setting"][env]["serverhost"]
         var datetypejson = "todaypridejson"
         var _this = this
         axios.get(serverhost+"/v1/prideyearDate",{headers:{"client":datetypejson}}).then(
         response=>{
    
        console.log(response.data)
        var years = response.data.years   
         years.sort((a, b) => {
          return b - a;
        }); 
        _this.years =  years


         })
      }

      },
      mounted(){
       
       
        this.getpride("year")
       this.getprideyearDate()
      },




   



      setup () {

 

 

    
     onUpdated(() => {
      
      }
      
      
      
      )

 
    // onUpdated(() => {
    //    alert(this.loaded.value)
    //   if (!this.loaded) { // 检查状态标志
    //     $('#magazine').turn({gradients: true, acceleration: true});
    //     console.log(1)
    //   }
    // })
   
   }


    
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="scss">

 



   .handwritingfont{

    font-family: "handwriting1";
    font-size: 20px;
   }


    .bookpage{
        display: flex;
        flex-direction: column;
    }
    #back{
       display: flex;
  
    //    flex-direction: column;
       .backbutton{
        font-family: "handwriting1";
        margin-left:auto
   
       }

    }
   
    #reference{
      font-family: fangsong;
      text-align: left;
      position:absolute;
      left: 40%;
    }

    #magazine{
        font-family: "handwriting1";
        font-size: 32px;
        color:black;
        width: 70vw;
        height: 88vh;
        left:15vw;
        // transition: transform 2s linear;
    
   

    .page{
   
        background: var(--maincolor);
        display:flex;
        flex-direction: column;
        // justify-content: center;
    
    
        .datetext{
            margin-top:20px;
            text-align: left;
            margin-left: 50px;
            font-size: x-large;
            font-weight: 900;
        }
    }
    }


   .bookcover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: black; */
    /* color:white; */
    .title{
        font-size: x-large;
        font-weight: 900;
    }
   }


  .all{
    width:100vw;
    height: 100vh;
    background:#e5f0f9;
  }

  #magazine .turn-page{
    background-color:#ccc;
  }

  .sidebar {
			position: fixed;
			top: 0;
			left: 0;
			width: 200px;
			height: 100vh;
			background-color: #f1f1f1;
			padding: 20px;
			border: 1px solid #ccc;
      overflow: scroll;
		}

		/* Style the book list */
		.book-list {
			list-style-type: none;
			margin: 0;
			padding: 0;
      overflow-y: auto;
		}

		/* Style each book item */
		.book {
			margin-bottom: 20px;
		}

		/* Style the book title */
		.book-title {
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 10px;
		}

		/* Style the book author */
		.book-author {
			font-size: 16px;
			margin-bottom: 10px;
		}

    .firstpage{
      background: #dda2a2;
      height: 22vh;
    }

    </style>
    